import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse , HttpHeaders ,HttpParams } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Observable } from "rxjs";
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';
import { v1 as uuidv1 } from 'uuid';

import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: any;
  clientId: any;
  tokenCredentials: any ;
  messageBody: any;
  // httpUrl = "http://34.205.54.220:15000/bcv";
  httpUrl = "https://bcvbackend.app.protoexpress.com/bom";
  // in staging use bcvbackend API
  // httpUrl = "https://bcvstaging.app.protoexpress.com/bcv";

  constructor(private httpClient: HttpClient,private storage: Storage) { 
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    storage.get('clientId').then((val) => {
      if(val){
        this.clientId = val;
      }else{
        storage.set('clientId', uuidv1());
      }

      // this.dologin();
    });

    storage.get('tokenCredentials').then((val) => {
      if(val){
        this.tokenCredentials = JSON.parse(val);
      }else{
        // this.dologin();
      }
    });
  }


  imageUpload(formData){
    let precessedData: any = true;
    let contentTypeData: any = true;
    var imghttpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'processData': precessedData,
        "contentType": contentTypeData,
        // "Accept": "application/json",
        // "Content-Type": "*"
      })
      // responseType: 'text' as const
    }
    // console.log(formData);
    // return this.httpClient.post("https://0wce7napd6.execute-api.us-east-1.amazonaws.com/dev/bcvtest", formData, imghttpOptions);
    // return this.httpClient.post("https://0wce7napd6.execute-api.us-east-1.amazonaws.com/dev/partsearch", formData, imghttpOptions);
    return this.httpClient.post(this.httpUrl+"/compare", formData, imghttpOptions);
    // return this.httpClient.post(this.httpUrl+"/validate-compare", formData, imghttpOptions);
 }


}
